<template>
  <sign-page
    title-text="职务管理"
    :request="request"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    @dataUpdate="dataUpdate"
    table-size="large"
    :form-parms-update="formParmsUpdate"
    tableActionsFixed
    :dealFormData="dealFormData"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  positionRequest as request
} from '@/api'

export default {
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '名称',
          key: 'name',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '编码',
          key: 'code'
        },
        {
          type: 'select',
          label: '所属机构',
          selectDatas: this.$store.getters.orgList,
          key: 'orgId'
        },
        {
          type: 'input',
          label: '职务设置人数',
          key: 'workerNum',
          check: {
            required: true,
            rules: {
              type: 'integer'
            }
          }
        // },
        // {
        //   type: 'input',
        //   label: '职工类型配置',
        //   key: 'workerTypeConf'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '名称',
          field: 'name',
          sort: true
        },
        {
          title: '编码',
          field: 'code',
          sort: true
        },
        {
          title: '所属机构',
          field: 'orgName',
          sort: true,
          width: 250
          // render: (h, rowData) => {
          //   let text = []
          //   let i = 1
          //   while (i < 5) {
          //     if (rowData['orgName' + i]) {
          //       text.push(rowData['orgName' + i])
          //     }
          //     i += 1
          //   }
          //   return h('div', text.join('-'))
          // }
        },
        {
          title: '机构编号',
          field: 'orgCode',
          sort: true
        },
        {
          title: '职务设置人数',
          width: 150,
          field: 'workerNum',
          sort: true,
          dataType: Number
        },
        {
          title: '职务现有人数',
          width: 160,
          field: 'workerCount',
          sort: true,
          dataType: Number
        },
        {
          title: '职务现有可执行人数',
          field: 'canWorkerCount',
          width: 180,
          sort: true,
          dataType: Number
        }]
        return data
      }
    }
  },
  created () {
    if (this.$authFunsProxy.add || this.$authFunsProxy.edit) {
      this.$store.dispatch('loadOrgList')
    }
  },
  methods: {
    dataUpdate () {
      this.$store.dispatch('loadPositionList', true)
    },
    dealFormData (data) {
      data.workerNum = data.workerNum >= 0 ? data.workerNum : 0
      return data
    }
  },
  data () {
    return {
      request: Object.assign(request, {
        get: request.getBaseCount
      })
    }
  }
}
</script>
